import React from "react";
import "./footer.css";
import { SiInstagram } from "react-icons/si";
import { FiGithub } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa6";


const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Lucas Devaney</h1>

        {/* <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>

          <li>
            <a href="#portfolio" className="footer__link">
              Projects
            </a>
          </li>

          <li>
            <a href="#testimonials" className="footer__link">
              Testimonials
            </a>
          </li>
        </ul> */}

        <div className="footer__social">
          <a
            href="https://github.com/lucasdevaney"
            className="footer__social-link"
            target="_blank"
          >
            <i class="uil uil-github">
              <FiGithub />
            </i>
          </a>

          <a
            href="https://br.linkedin.com/in/lucas-devaney-ba276018"
            className="footer__social-link"
            target="_blank"
          >
            <i class="uil uil-linkedin">
              <FaLinkedinIn />
            </i>
          </a>

          <a
            href="https://www.instagram.com/lucasdevaney/"
            className="footer__social-link"
            target="_blank"
          >
            <i class="uil uil-instagram">
              <SiInstagram />
            </i>
          </a>
        </div>

        <span className="footer__copy">
            &#169; Lucas Devaney. All rights reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
