import React, { useState } from "react";
import "./qualification.css";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { PiBriefcase } from "react-icons/pi";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personal journey</span>
      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active"
                : "qualification__button"
            }

            onClick={() => toggleTab(1) }
          >
            <i className="uil uil-graduation-cap qualification__icon">
              <HiOutlineAcademicCap />
            </i>
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active"
                : "qualification__button"
            }
            onClick={() => toggleTab(2) }
          >
            <i className="uil uil-briefcase-alt qualification__icon">
              <PiBriefcase />
            </i>
            Experience
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web Development and Internet Applications</h3>
                <span className="qualification__subtitle">
                  Canada - College
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2023 - 2024
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Accessibility for Ontarians with Disabilities Act (AODA) </h3>
                <span className="qualification__subtitle">
                  Canada - Worksite Safety
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2024
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Unity 5: 3D Essential Training</h3>
                <span className="qualification__subtitle">
                  LinkedIn
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2016
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Digital Media Design</h3>
                <span className="qualification__subtitle">
                  Brazil - College
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2006 - 2008
                </div>
              </div>
            </div>
          </div>

          <div 
          className={
            toggleState === 2
              ? "qualification__content qualification__content-active"
              : "qualification__content"
          }
          >

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Founder and CIO</h3>
                <span className="qualification__subtitle">
                  Starty - Brazil
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2015 - Present
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Multimedia Designer</h3>
                <span className="qualification__subtitle">
                  Affero Lab - Brazil
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2010 - 2013
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">IT Manager</h3>
                <span className="qualification__subtitle">Autarquia Hospitalar Municipal - Brazil</span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i>
                  2009 - 2010
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
