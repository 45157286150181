import React, { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import "./services.css";
import { RiEditBoxLine } from "react-icons/ri";
import { GrChapterAdd } from "react-icons/gr";
import { MdOutlineColorLens } from "react-icons/md";

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="section services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">What I offer</span>

      <div className="services__container container grid">
        <div className="services__content">
          <div>
            <i className="uil uil-web-grid services__icon">
              <RiEditBoxLine />
            </i>
            <h3 className="services__title">
              Product <br /> Designer
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(1)}>
            View More
            <i className="uil uil-arrow-right services__button-icon">
              <FaArrowRight />
            </i>
          </span>

          <div
            className={
              toggleState === 1
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times
                            services__modal-close"
              >
                <IoClose />
              </i>

              <h3 className="services__modal-title">Product Designer</h3>
              <p className="services__modal-description">
                I conduct user research, create prototypes, collaborate in
                workshops, and integrate feedback to develop innovative products
                and refine designs. Here are the services I provide:
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    User Research and Analysis
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Prototyping and Wireframing
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Iterative Design Development
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    User Testing and Feedback Integration
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Collaborative Design Workshops
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                      <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Web development and Internet Applications
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Product Roadmap and Strategy Planning
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-arrow services__icon">
              <GrChapterAdd />
            </i>
            <h3 className="services__title">
              UI/UX <br /> Designer
            </h3>
          </div>

          <span onClick={() => toggleTab(2)} className="services__button">
            View More
            <i className="uil uil-arrow-right services__button-icon">
              <FaArrowRight />
            </i>
          </span>

          <div
            className={
              toggleState === 2
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times
                            services__modal-close"
              >
                <IoClose />
              </i>

              <h3 className="services__modal-title">UI/UX Designer</h3>
              <p className="services__modal-description">
                With a focus on information architecture and usability testing,
                I ensure seamless user experiences from start to finish. Here
                are the services I provide:
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">User Interface Design</p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    User Experience Research
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">Interaction Design</p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Information Architecture
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">Usability Testing</p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Visual Design Implementation
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-edit services__icon">
              {" "}
              <MdOutlineColorLens />
            </i>
            <h3 className="services__title">
              Visual <br /> Designer
            </h3>
          </div>

          <span onClick={() => toggleTab(3)} className="services__button">
            View More
            <i className="uil uil-arrow-right services__button-icon">
              <FaArrowRight />
            </i>
          </span>

          <div
            className={
              toggleState === 3
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times
                            services__modal-close"
              >
                <IoClose />
              </i>

              <h3 className="services__modal-title">Visual Designer</h3>
              <p className="services__modal-description">
                I specialize in branding, graphic design, illustration, motion
                graphics and typography. Here are the services I provide:
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Branding and Identity Design
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Graphic Design for Print and Digital Media
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Illustration and Iconography
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Motion Graphics and Animation
                  </p>
                </li>

                <li className="services__modal-service">
                  <i
                    className="uil uil-check-circle
                                    services__modal-icon"
                  >
                    <FaRegCheckCircle />
                  </i>
                  <p className="services__modal-info">
                    Visual Style Guides and Brand Manuals
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
