import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";

const Frontend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Frontend developer</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">HTML</h3>
                            {/* <span className="skills__level">Advanced</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">CSS/SCSS</h3>
                            {/* <span className="skills__level">Advanced</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">JavasScript</h3>
                            {/* <span className="skills__level">Intermediate</span> */}
                        </div>
                    </div>
                </div>
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">Bootstrap</h3>
                            {/* <span className="skills__level">Intermediate</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">SEO</h3>
                            {/* <span className="skills__level">Intermediate</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">React</h3>
                            {/* <span className="skills__level">Intermediate</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Frontend
