import Work1 from "../../assets/work1.png";
import Work2 from "../../assets/work2.png";
import Work3 from "../../assets/work3.png";
import Work4 from "../../assets/work4.png";
import Work5 from "../../assets/work5.png";
import Work6 from "../../assets/work6.png";
import Work7 from "../../assets/work7.png";
import Work8 from "../../assets/work8.png";
import Work9 from "../../assets/work9.png";
import Work10 from "../../assets/work10.png";
import Work11 from "../../assets/work11.png";
import Work12 from "../../assets/work12.png";
import Work13 from "../../assets/work13.png";
import Work14 from "../../assets/work14.png";
import Work15 from "../../assets/work15.png";
import Work16 from "../../assets/work16.png";

export const projectsData = [
{
  id: 1,
  image: Work11,
  title: "Google Playbook Art Design",
  category: "Design",
},
  {
    id: 2,
    image: Work1,
    title: "Visual Identity",
    category: "design",
  },
  {
    id: 3,
    image: Work2,
    title: "Visual Identity",
    category: "design",
  },
  {
    id: 4,
    image: Work3,
    title: "Visual Identity",
    category: "design",
  },
  {
    id: 5,
    image: Work4,
    title: "Visual Identity",
    category: "design",
  },
  {
    id: 6,
    image: Work5,
    title: "Magazine Graphic Design",
    category: "Design",
  },
  {
    id: 7,
    image: Work6,
    title: "Flyer and Infographic",
    category: "Design",
  },
  {
    id: 8,
    image: Work7,
    title: "Magazine Graphic Design & Content",
    category: "Design",
  },
  {
    id: 9,
    image: Work8,
    title: "Augmented Reality Board Game",
    category: "Multimedia",
  },
  {
    id: 10,
    image: Work9,
    title: "Learning Map Game",
    category: "Multimedia",
  },
  {
    id: 11,
    image: Work10,
    title: "Serious Game Interface",
    category: "Multimedia",
  },

  {
    id: 12,
    image: Work12,
    title: "Website Development & Design",
    category: "Multimedia",
  },

  {
    id: 13,
    image: Work13,
    title: "People Caricature Illustrations",
    category: "Illustration",
  },

  {
    id: 14,
    image: Work14,
    title: "Digital painting and textures",
    category: "Illustration",
  },
  
  {
    id: 15,
    image: Work15,
    title: "Hand-painted Fabric",
    category: "Illustration",
  },

  {
    id: 16,
    image: Work16,
    title: "Fabric Pattern Design",
    category: "Illustration",
  },
];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'design',
    },
    // {
    //     name: 'branding',
    // },
    {
        name: 'multimedia',
    },
    {
      name: 'illustration',
  },
];