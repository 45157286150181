import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";

const Backend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Backend developer</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">PHP</h3>
                            {/* <span className="skills__level">Basic</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">Node JS</h3>
                            {/* <span className="skills__level">Basic</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">Git</h3>
                            {/* <span className="skills__level">Basic</span> */}
                        </div>
                    </div>
                </div>
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">MySQL</h3>
                            {/* <span className="skills__level">Intermediate</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">Firebase</h3>
                            {/* <span className="skills__level">Basic</span> */}
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"><FaRegCheckCircle />
                        </i>
                        <div>
                            <h3 className="skills_name">SQL</h3>
                            {/* <span className="skills__level">Basic</span> */}
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Backend
