import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import Popup from "./Popup";




const WorkItems = ({ item }) => {

    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
      setShowPopup(true);
    };
  
    const closePopup = () => {
      setShowPopup(false);
    };


return (
    <div className="work__card" key={item.id}>
      <img src={item.image} alt="" className="work__img" />
      <h3 className="work__title">{item.title}</h3>
      <button className="work__button" onClick={openPopup}>
        View <i className="bx bx-right-arrow-alt work__button-icon">< FaArrowRight /></i>
      </button>
      {showPopup && <Popup imageUrl={item.image}  onClose={closePopup} />}
      
      </div>
  );
};

export default WorkItems;
