import React from 'react';
import { SiInstagram } from "react-icons/si";
import { FiGithub } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa6";

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://github.com/lucasdevaney" className="home__social-icon" target="_blank">
        <i class="uil uil-github"><FiGithub /></i>
        </a>

        <a href="https://br.linkedin.com/in/lucas-devaney-ba276018" className="home__social-icon" target="_blank">
        <i class="uil uil-linkedin"><FaLinkedinIn /></i>
        </a>

        <a href="https://www.instagram.com/lucasdevaney/" className="home__social-icon" target="_blank">
        <i class="uil uil-instagram"><SiInstagram /></i>
        </a>
    </div>

  )
}

export default Social
