import React, { useState } from "react";
import "./header.css";
import { FiHome } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineLike } from "react-icons/ai";
import { RiBriefcase2Line } from "react-icons/ri";
import { FaRegStar } from "react-icons/fa";
import { LuContact2 } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { IoAppsOutline } from "react-icons/io5";
import Contact from "../contact/Contact";


const Header = () => {
  /*=============== Change Background Header ===============*/
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    // when the scroll is heigher than 200 viewport height, add the show-header class to a tag with the header tag
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-header");
  });

  /*=============== Toggle Menu ===============*/
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");

  return (
    <header className="header">
      <nav className="nav container">
      <a href="index.html" className="nav__logo">
      <svg
                  width="36"
                  height="36"
                  viewBox="0 0 123.9 134.3"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ld__logo"
                >
  <path
    d="M122.6,54c-0.9-4.3-2.2-8.5-3.9-12.6c-1.7-4-3.8-7.8-6.2-11.4
    c-2.4-3.5-5.1-6.9-8.2-9.9c-3-3-6.3-5.8-9.9-8.2 c-3.6-2.4-7.4-4.5-11.3-6.2
    c-4-1.7-8.2-3.1-12.5-4c-4.4-0.9-8.9-1.4-13.4-1.4L32.7,0.2v17.9l24.4,0.1h0h0c27,0.1,49,22.2,49,49.2
    c0,13.2-5.1,25.6-14.5,34.9c-1.3,1.3-2.7,2.5-4.1,3.7l-0.2,0.2l-0.1,0.1c-2.1,1.6-4.3,3-6.6,4.3
    c0.3-0.5,0.5-0.9,0.7-1.4 c3.3-6.4,4.9-13.4,4.9-20.7c0-3.1-0.3-6.2-0.9-9.2c-0.6-2.9-1.5-5.8-2.7-8.6
    c-1.2-2.7-2.6-5.3-4.2-7.8 c-1.6-2.4-3.5-4.7-5.6-6.8c-2.1-2.1-4.4-4-6.8-5.6c-2.5-1.7-5.1-3.1-7.8-4.3
    c-2.8-1.2-5.7-2.1-8.6-2.7 c-3-0.6-6.1-1-9.2-1h0h0l-7.7,0v17.8l7.6,0C55.8,60.3,68.4,73,68.4,88.5c0,4.5-1,8.8-3,12.7l0,0
    l0,0c-1.3,2.7-3.1,5.1-5.3,7.3l0,0l0,0c-4.4,4.4-10.1,7.2-16.3,8l-26,0L17.9,0 H0l0,116.5h0v17.7h0v0.1h44.4c0.3,0,0.6-0.1,0.9-0.1
    h18.5c3.5,0,7.4-0.7,11.6-2c3.9-1.2,8-2.9,11.8-5c3.3-1.7,6.6-3.9,9.8-6.2 c2.7-2,5.3-4.2,7.2-6.1c3.2-3.1,6-6.6,8.5-10.2
    c2.4-3.6,4.5-7.5,6.2-11.5c1.7-4,3-8.2,3.8-12.5c0.9-4.3,1.3-8.8,1.3-13.2 C123.9,62.9,123.5,58.4,122.6,54z" fill="#ffce00"
  />
</svg>

</a>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a
                href="#home"
                onClick={() => setActiveNav("#home")}
                className={
                  activeNav === "#home" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-estate nav__icon">
                  <FiHome />
                </i>
                Home
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#about"
                onClick={() => setActiveNav("#about")}
                className={
                  activeNav === "#about" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-user nav__icon">
                  <FaRegUser />
                </i>
                About
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#skills"
                onClick={() => setActiveNav("#skills")}
                className={
                  activeNav === "#skills"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-file-alt nav__icon">
                  <AiOutlineLike />
                </i>
                Skills
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#services"
                onClick={() => setActiveNav("#services")}
                className={
                  activeNav === "#services"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-briefcase nav__icon">
                  <RiBriefcase2Line />
                </i>
                Services
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#portfolio"
                onClick={() => setActiveNav("#portfolio")}
                className={
                  activeNav === "#portfolio"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-scenery nav__icon">
                  <FaRegStar />
                </i>
                Portfolio
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#contact"
                onClick={() => setActiveNav("#contact")}
                className={
                  activeNav === "#contact"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-message nav__icon">
                  <LuContact2 />
                </i>
                Contact
              </a>
            </li>
          </ul>
          <div className="nav__close" onClick={() => showMenu(!Toggle)}>
            <i className="uil uil-times">
              <IoClose />
            </i>
          </div>
        </div>

        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps">
            <IoAppsOutline />
          </i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
