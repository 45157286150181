import React from 'react'
import "./work.css";
import { IoClose } from "react-icons/io5";


const Popup = ({imageUrl, onClose}) => {
    
  return (
    <div className="popup">
      <div className="popup__content">
        <img src={imageUrl} alt="Project" className="popup__image" />
        <button className="popup__close" onClick={onClose}>
          < IoClose />
        </button>
      </div>
    </div>
  )
}

export default Popup;
