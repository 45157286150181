import React from 'react'
import { BiAward } from "react-icons/bi";
import { RiBriefcase2Line } from "react-icons/ri";
import { FaRegStar } from "react-icons/fa";

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
            <i className="bx bx-award about__icon"><BiAward /></i>
            <h3 className="about__title">Experience</h3>
            <span className="about__subtitle">15 Years Working</span>
        </div>

        <div className="about__box">
            <i className="bx bx-briefcase-alt about__icon"><RiBriefcase2Line /></i>
            <h3 className="about__title">Completed</h3>
            <span className="about__subtitle">250 + projects</span>
        </div>

        <div className="about__box">
            <i className="bx bx-support about__icon"><FaRegStar /></i>
            <h3 className="about__title">Clients</h3>
            <span className="about__subtitle">40 + Happy</span>
        </div>

    </div>
  )
}

export default Info
